<template>
  <div class="career">
    <div class="banner">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="item in 5" :key="item" class="slide">
          <div class="text"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="content">
      <div class="title">Why Joining GV20?</div>
      <div class="olist1">
        <div class="list1"><span>Push the frontier of cancer drug discovery and development.</span></div>
        <div class="list1"><span>Join a global team of creative, collaborative, and committed computational biologists, data scientists, cancer biologists, immunologists, and drug hunters to create transformative medicines for patients.</span></div>
        <div class="list1"><span>Be in an inclusive environment where you are empowered to do your best work.</span></div>
      </div>

      <div class="title">Job Openings</div>
      <!--<h3 class="sub-title">US</h3>
-->

      <!--<h3 class="sub-title">China</h3>
-->

      <div class="cards">
        <div class="new_card">
          <div class="title">Join Us</div>
          <div class="text">
            Interested in joining the GV20 team but don’t see a suitable job opening? 
            <br>Please submit your CV and your preferred job position to 
            <a href="mailto:career@gv20tx.com">career@gv20tx.com</a>
          </div>
        </div>
      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {
      swiperOptions: {
        slidesPerView: 1,//显示个数
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: true
      },
      listStatus: {
        item1: false,
        item2: false,
        item3: false,
        item4: false,
        item5: false,
        item6: false,
        item7: false
      }
    }
  },
  components: {
    Footers
  }
}
</script>
<style lang="scss" scoped>
$padding-left: 50px;
.olist1 {
  margin: 0 0.7rem;
  /*width: 100%;*/
  .list1 {
    font-size: 30px;
    &::before {
      content: '⟀';
      font-size: 36px;
      margin-right: 10px;
    }
    span {
      width:90%;
      vertical-align: text-top;
      display: inline-block;
    }
  }
}
.career {
  .banner {
    color: #fff;
    .title {
      margin-bottom: 40px;
      font-family: LucidaGrande-Bold, LucidaGrande;
    }
    .slide {
      height: 520px;
    }
    .slide:nth-of-type(1) {
      background: url(../assets/career/1.jpg) no-repeat center center/cover;
    }
    .slide:nth-of-type(2) {
      background: url(../assets/career/2.jpg) no-repeat center center/cover;
    }
    .slide:nth-of-type(3) {
      background: url(../assets/career/3.jpg) no-repeat center center/cover;
    }
    .slide:nth-of-type(4) {
      background: url(../assets/career/4.jpg) no-repeat center center/cover;
    }
    .slide:nth-of-type(5) {
      background: url(../assets/career/5.jpg) no-repeat center center/cover;
    }
    .text {
      padding: 60px 0 0 40px;
      font-size: 50px;
      font-family: LucidaGrande-Bold, LucidaGrande;
    }
    /deep/ .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      background: #c5c4bf;
    }
    /deep/ .swiper-pagination-bullet-active {
      background: #fff;
    }
  }
  .cards {
    .new_card {
      padding: 104px 160px 160px 62px;
      .title
      {
        margin: 0px;
      }
      .text
      {
        margin-top: 60px;
      }
      a {
        font-weight: bold;
        text-decoration: underline;
        color: #212529;
      }
    }
  }
  .title {
    margin: 80px 62px;
    font-weight: bold;
    font-size: 50px;
  }
  .text {
    font-size: 40px;
  }
  .info {
    font-size: 30px;
  }
  .small {
    font-size: 32px;
    font-family: Helvetica-Oblique, Helvetica;
    font-weight: normal;
    color: #3852a3;
    line-height: 44px;
  }
  .row {
    margin: 0;
    // height: 200px;
  }
  .sub-title
  {
    padding: 0px 0px 0px 62px;
    margin: 0px;
    height: 160px;
    font-size: 40px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #1F1F1F;
    line-height: 160px;
  }
  .title + .sub-title
  {
    margin-top: -40px;
  }
  .list
  {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #979797;
    .item
    {
      display: flex;
      flex-direction: column;
      max-height: 200px;
      overflow: hidden;
      transition: all 0.3s;
      background: #3258A7;
      color: #fff;
      &.open
      {
        max-height: 15000px;
        .btn-title
        {
          &::before
          {
            background-image: url('../assets/career/icon-row-close.png');
          }
        }
      }
      .btn-title
      {
        position: relative;
        padding-left: 62px;
        /*font-size: 32px;*/
        font-size: 26px;
        color: #FFFFFF;
        height: 200px;
        line-height: 200px;
        text-decoration: none;
        &::before
        {
          content: '';
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
          width: 50px;
          height: 50px;
          background-image: url('../assets/career/icon-row-open.png');
          background-position: center;
          background-size: 50px auto;
          background-repeat: no-repeat;
        }
      }
      .desc
      {
        padding: 60px 42px 60px 62px;
        background: #fff;
        h4
        {
          margin-top: 60px;
          margin-bottom: 25px;
          font-size: 40px;
          font-weight: bold;
          color: #2C2E30;
          line-height: 60px;
        }
        h5
        {
          margin-top: 40px;
          font-size: 40px;
          color: #2C2E30;
          line-height: 50px;
          font-weight: normal;
          a
          {
            text-decoration: underline;
            font-weight: bold;
            color: #2C2E30;
          }
        }
        .row-number
        {
          position: relative;
          margin-top: 40px;
          padding-left: 56px;
          font-size: 40px;
          color: #2C2E30;
          line-height: 48px;
          .no
          {
            position: absolute;
            top: 0px;
            left: 0px;
            font-style: normal;
          }
        }
        .row-number + h4
        {
          margin-top: 60px;
        }
        .row-number + h5
        {
          margin-top: 60px;
        }
      }
    }
  }
}
</style>
